import React, { useState } from "react";
import "./switch.scss";

interface Props {
  isChecked: any;
  setIsChecked: any;
}
export const FToggle = ({ isChecked, setIsChecked }: Props) => {
  return (
    <div>
      <label className="switch">
        <input
          type="checkbox"
          checked={isChecked} // Set checked state based on the isChecked prop
          onChange={(e) => {
            setIsChecked(e.target.checked); // Update isChecked state based on user interaction
          }}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};
